





















































































import { mapGetters, mapState, mapMutations } from 'vuex';
import { getCharacterArt } from '../../character-arts-placeholder';
import SmallBar from '../SmallBar.vue';
import { CharacterPower, CharacterTrait } from '../../interfaces';
import EarningsCalculator from './EarningsCalculator.vue';
import { RequiredXp } from '../../interfaces';
import Hint from '../Hint.vue';
import Vue from 'vue';
import { toBN, fromWeiEther } from '../../utils/common';

export default Vue.extend({
  components: {
    SmallBar,
    Hint,
    EarningsCalculator,
  },

  computed: {
    ...mapState(['maxStamina', 'currentCharacterId', 'ownedCharacterIds']),
    ...mapGetters([
      'currentCharacter',
      'currentCharacterStamina',
      'getCharacterName',
      'getCharacterStamina',
      'charactersWithIds',
      'ownCharacters',
      'timeUntilCharacterHasMaxStamina',
      'getIsInCombat',
      'getIsCharacterViewExpanded',
      'fightGasOffset',
      'fightBaseline'
    ]),

    isLoadingCharacter(): boolean {
      return !this.currentCharacter;
    },

    filteredCharactersForList(): any {
      const items: any  = this.ownCharacters;
      return items;
    }
  },

  data() {
    return {
      traits: CharacterTrait,
      isPlaza : false,
    };
  },
  methods: {
    ...mapMutations(['setCurrentCharacter']),
    getCharacterArt,
    CharacterPower,
    RequiredXp,

    setListClassForSelChar(id: string, currentCharId: string): any {
      if (id === currentCharId){
        return 'character-highlight';
      }

      else return 'character';
    },

    toolTipHtml(time: string): string {
      return 'Regenerates 1 point every 5 minutes, stamina bar will be full at: ' + time;
    },

    formattedSkill(skill: number): number {
      const skillBalance = fromWeiEther(skill.toString());
      return toBN(skillBalance).toNumber();
    },
  },
});
